import {createAsyncThunk} from '@reduxjs/toolkit'
import {ControllerParams} from 'yoshi-flow-editor-runtime'
import {getEventDetailsRelativeUrl} from '../services/location'
import {ThunkConfig} from './interfaces'

export const navigateToEventDetails = createAsyncThunk<void, void, ThunkConfig>(
  'NAVIGATE',
  async (_, {extra: {controllerParams}, getState}) => {
    const state = getState()
    const url = await getEventDetailsRelativeUrl(controllerParams, state.event)
    const wixCodeApi = (controllerParams as ControllerParams).controllerConfig.wixCodeApi
    wixCodeApi.location.to(url)
  },
)

export type NavigateToEventDetails = typeof navigateToEventDetails
